<template>
  <div class="about-edit fade-up">
    <el-row :gutter="30" style="position: relative;">
      <el-col :span="16">
        <el-card v-loading="langLoading" class="resource-content-card">
          <div class="card-header" slot="header">
            <div class="lang-section">
              <h3>Тилни танланг:</h3>
              <el-radio-group v-model="selectedLang">
                <el-radio-button label="oz">O'zbekcha</el-radio-button>
                <el-radio-button label="uz">Узбекча</el-radio-button>
                <el-radio-button label="ru">Русский</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div
            class="title-section"
            :class="{
              'invalid-error': $v.form[`title_${selectedLang}`].$error
            }"
          >
            <h3>Сарлавҳа</h3>
            <el-input
              size="large"
              placeholder="Сарлавҳани киритинг"
              v-model="$v.form[`title_${selectedLang}`].$model"
            ></el-input>
          </div>
          <div class="content-section">
            <h3>Контент</h3>
            <quill-editor
              class="editor"
              v-model="form[`content_${selectedLang}`]"
            />
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" style="position: sticky; top: 0">
        <el-card>
          <div class="photo-upload-section">
            <h4>Амаллар</h4>
            <el-button
              type="success"
              :disabled="isLoading"
              @click="saveAboutUs"
              icon="el-icon-check"
            >
              Сақлаш
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    isLoading: false,
    form: {
      title_uz: "",
      title_oz: "",
      title_ru: "",
      content_uz: "",
      content_oz: "",
      content_ru: ""
    },
    selectedLang: "oz",
    langLoading: false
  }),
  watch: {
    selectedLang() {
      this.langLoading = true;
      setTimeout(() => {
        this.langLoading = false;
      }, 500);
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    this.$store
      .dispatch("getAboutUs")
      .then(aboutUs => {
        this.form = { ...aboutUs };
      })
      .finally(() => loading.close());
  },
  methods: {
    saveAboutUs() {
      if (this.isLoading) return;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify.error({
          title: "Диққат!",
          message: "Формани тўлдиринг!"
        });

        return false;
      } else {
        this.isLoading = true;
        const loading = this.$loading({
          lock: true
        });
        this.$store
          .dispatch("updateAboutUs", this.form)
          .then(() => {
            this.$message({
              type: "success",
              message: "Сақланди"
            });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "Хато!"
            });
          })
          .finally(() => {
            this.isLoading = false;
            loading.close();
          });
      }
    }
  },
  validations: {
    form: {
      title_uz: { required },
      title_ru: { required },
      title_oz: { required }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-section,
.content-section,
.desc-section,
.hashtag-section,
.members-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
}

.resource-content-card {
  position: relative;
  .el-card__header {
    position: sticky;
    top: 0;
  }
}

.lang-section h3 {
  margin-bottom: 10px;
}

.about-edit {
  padding: 20px;
}

// .editor {
//   height: 400px;
//   display: flex;
//   flex-direction: column;
// }

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.category-section,
.photo-upload-section,
.date-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 10px;
  }
}

.desc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-input {
  margin-top: 10px;
  width: 100%;
}
</style>
